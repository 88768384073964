import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 mt-[100px]">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">Privacy Policy</h1>
          <h1 className="text-xl mb-3">We don’t share your information with third-party entities          </h1>
          <h1 className="text-lg mb-3">GYPR CONSULTING LLC</h1>


          <p className="text-gray-700 text-base mb-4">
            GYPR CONSULTING LLC (“GYPR CONSULTING LLC” or “we”) is a staffing and recruiting agency that provides temporary and permanent job placement services to individuals seeking employment opportunities. We understand the importance of protecting the personal and confidential information of our clients, candidates, and employees. This privacy policy outlines how we collect, use, and disclose personal data in accordance with applicable laws and regulations.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Scope</h2>
          <p className="text-gray-700 text-base mb-4">
            This privacy policy applies to all personal data collected by GYPR CONSULTING LLC, including but not limited to:
          </p>
          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>Candidates who register with us for job opportunities</li>
            <li>Clients who engage our services for staffing and recruiting needs</li>
            <li>Employees who work with us or on behalf of our clients</li>
            <li>
              Visitors to our website{" "}
              <a
                href="https://www.gyprc.com/"
                className="text-blue-600 hover:underline"
              >
                https://www.gyprc.com/
              </a>
            </li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Types of Personal Data Collected
          </h2>
          <p className="text-[16px] pb-2">GYPR CONSULTING LLC collects the following types of personal data:</p>
          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>Contact information: name, email address, phone number, physical address</li>
            <li>
              Professional information: work experience, skills, education, certifications
            </li>
            <li>Job search information: job preferences, job applications, resume and cover letter</li>
            <li>Employment history: employment dates, job titles, employer names</li>
            <li>Financial information: payment information (for invoicing purposes only)</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            How We Collect Personal Data
          </h2>
          <p className="text-[16px] pb-2">GYPR CONSULTING LLC collects the following types of personal data:</p>

          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>Online forms: our website, job application forms, candidate registration forms</li>
            <li>
              Phone and email communications: conversations with candidates, clients, and
              employees
            </li>
            <li>In-person interactions: meetings and interviews with candidates and clients</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">How We Use Personal Data</h2>
          <p className="text-[16px] pb-2">We use personal data for the following purposes:</p>

          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>To match candidates with job openings that match their skills and qualifications</li>
            <li>To provide staffing and recruiting services to clients</li>
            <li>To communicate with candidates about job opportunities and application status</li>
            <li>To process payments for our services</li>
            <li>To maintain accurate records of candidate and client interactions</li>
            <li>To improve our services and provide better candidate matching</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            How We Disclose Personal Data
          </h2>

          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>To government authorities: as required by law or regulation</li>

          </ul>


          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Security Measures
          </h2>
          <p className="text-gray-700 text-base mb-4">
            We implement appropriate technical and organizational measures to ensure the
            security of personal data. These measures include:
          </p>
          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>Encryption of sensitive data (e.g., payment information)</li>
            <li>Secure servers and databases</li>
            <li>Limited access to personal data based on need-to-know principle</li>
            <li>Regular backups and disaster recovery procedures</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Data Retention
          </h2>
          <p className="text-gray-700 text-base mb-4">
            We retain personal data for as long as necessary to fulfill our business purposes or as required by law or regulation. We will delete or anonymize personal data when it is no longer needed.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Rights of Individuals
          </h2>
          <p className="text-gray-700 text-base mb-4">
            Individuals have the right to:
          </p>
          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>Request access to their personal data held by GYPR CONSULTING LLC</li>
            <li>Request correction or erasure of their personal data</li>
            <li>Object to processing of their personal data for specific purposes</li>
            <li>Lodge a complaint with a supervisory authority</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
            Changes to this Privacy Policy
          </h2>

          <ul className="list-disc pl-6 text-gray-700 text-base mb-4">
            <li>  We reserve the right to update this privacy policy at any time. Changes will be effective immediately upon posting on our website. Your continued use of our services constitutes acceptance of the updated privacy policy.</li>
            <li>No mobile information will be sold or shared with third parties for promotional or marketing purposes".</li>

          </ul>

          <p style={{ fontStyle: "italic" }}>
            "We will not share your opt-in to an SMS campaign with any third party for purposes unrelated to providing you with the services of that campaign. We may share your Personal Data, including your SMS opt-in or consent status, with third parties that help us provide our messaging services, including but not limited to platform providers, phone companies, and any other vendors who assist us in the delivery of text messages

            All of the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties."
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contact Us</h2>
          <p className="text-gray-700 text-base mb-4">
            If you have any questions or concerns about this privacy policy, please
            contact us at:
          </p>
          <address className="text-gray-700 text-base mb-4">
            GYPR CONSULTING LLC <br />
            <a
              href="mailto:rahul.s@gyprc.com"
              className="text-blue-600 hover:underline"
            >
              info@gyprc.com
            </a>{" "}
            <br />
            +1 7576563147
          </address>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
